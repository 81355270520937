import React, {useEffect, useRef, useState} from 'react';
import * as styles from './default-subcategory.module.scss';

import Layout from "../page-components/layout/layout";
import { graphql } from "gatsby";
import GsHeader from "../page-components/header/gsHeader";
import DefaultSubcategoryProductCard from "./default-subcategory-product-card";
import GsButton from "../page-components/buttons/gsButton";

const DefaultCategoryTemplate = ( { data } ) => {

    const node = data.shopifyCollection;

    const [ filterVendor, setFilterVendor ] = useState(null);
    const [ filteredProducts, setFilteredProducts ] = useState(null);

    const productTypes = node.products.map( p => p.productType )
        .filter ( (val, idx, arr) => arr.indexOf(val) === idx );

    const [ filterProductTypes, setFilterProductTypes ] = useState( [ ...productTypes ]);

    const vendorSelectRef = useRef();

    const vendors = node.products.map( p => p.vendor ).filter( (value, index, self) => self.indexOf(value) === index );
    const subCategoryHandle = node.title.toLowerCase().trim();


    useEffect(
        () => {
            const searchParams = new URLSearchParams(window.location.search);
            const hersteller = searchParams.get("hersteller");

            if (hersteller) {
                setFilterVendor(hersteller);
            }
        },
        []
    )

    useEffect(
        () => {
            if (filterVendor !== null) {
                sessionStorage.setItem(subCategoryHandle + "_category_filter", filterVendor);
                filterAction();
            }

            if (!filterVendor) return;

            if ('URLSearchParams' in window) {
                const searchParams = new URLSearchParams(window.location.search);

                if (searchParams.get("hersteller") === filterVendor) {
                    return;
                }

                searchParams.set("hersteller", filterVendor);
                window.location.search = searchParams.toString();
            }

        },
        [ filterVendor ]
    );

    useEffect(
        () => {
            if (filterProductTypes !== null) {
                sessionStorage.setItem(subCategoryHandle + "_producttypes_filter", JSON.stringify(filterProductTypes));
                filterAction();
            }
        },
        [ filterProductTypes ]
    );

    const filterAction = async () => {

        const filterVendor = sessionStorage.getItem(subCategoryHandle + "_category_filter");

        console.log('*** filter vendor ', filterVendor);

        const filterProductTypes = JSON.parse(sessionStorage.getItem(subCategoryHandle + "_producttypes_filter"));

        let doFilterVendor = true;
        let doFilterProductTypes = true;

        if (!filterVendor || filterVendor.trim() === "") {
            doFilterVendor = false;
        }

        if (!filterProductTypes) {
            doFilterProductTypes = false;
        }

        if (!doFilterVendor && !doFilterProductTypes) {
            return;
        }

        let prods = [ ...data.shopifyCollection.products ];


        // Filtering of vendors
        if (doFilterVendor && vendors.indexOf(filterVendor) !== -1) {
            if (vendorSelectRef.current) {
                const select = vendorSelectRef.current;
                select.value = filterVendor;
            }

            for  (const p of prods) {
                if (p.vendor !== filterVendor) {
                    prods = prods.filter( f => f !== p);
                }
            }
        }

        if (doFilterProductTypes) {
            for (const p of prods) {

                if ( filterProductTypes.indexOf(p.productType) === -1 ) {
                    prods = prods.filter( f => f !== p);
                }
            }

        }


        setFilteredProducts(prods);

    }

    useEffect(
        () => {
            filterAction();
        },
        []
    );

    let isSale = false;

    if (node.title.toLowerCase().indexOf("sale") !== -1) {
        isSale = true;
    }

    return <Layout>
        <div className="container bg-white h-100">
            <div className="row">

                <GsHeader>
                    <h1>{ node.title }</h1>
                    {
                        node.descriptionHtml && <div dangerouslySetInnerHTML={
                            {
                                __html: node.descriptionHtml
                            }
                        } />
                    }

                    {isSale &&
                        <div className={"mt-3"}>
                            <h4>Bitte wählen Sie Ihre Kategorien aus</h4>

                            {
                                productTypes.map(
                                    p => <div className={"d-flex align-items-center my-2"}>
                                        <input type={"checkbox"}
                                               className={"c-pointer"}
                                               checked={ filterProductTypes.indexOf(p) !== -1 }
                                               onChange={
                                                   () => {
                                                       if (filterProductTypes.indexOf(p) !== -1) {
                                                            setFilterProductTypes(
                                                                [ ...filterProductTypes.filter(f => f !== p)]
                                                            );
                                                       } else {
                                                           // add again
                                                           setFilterProductTypes(
                                                               [ ...filterProductTypes, p ]
                                                           );
                                                       }
                                                   }
                                               }
                                        />
                                        <label className={"ms-2"}>
                                            { !p && <i>keine Zuordnung</i>}
                                            { p }
                                            &nbsp;&nbsp; <i>({node.products.map(p => p.productType).filter(f => f === p).length} Produkte)</i>
                                        </label>
                                    </div>
                                )
                            }

                        </div>
                    }

                </GsHeader>
            </div>

            <div className="row">
                <div className={styles.vendorWrapper + " px-4 py-4 d-flex align-items-center"}>
                    <label className={"me-3"}>Hersteller</label>

                    <select className={"flex-grow-2"}
                        value={filterVendor}
                            ref={vendorSelectRef}
                        onChange={
                            (event) => {
                                setFilterVendor(event.target.value);
                            }
                        }
                    >

                        <option></option>

                        { vendors.map( v =>
                            <option>{v}</option>
                        )}

                    </select>

                </div>
            </div>

            <div className={"row align-items-stretch"}>
                { !filteredProducts && data.shopifyCollection.products.map((p) => <DefaultSubcategoryProductCard p={p} />) }
                { filteredProducts && filteredProducts.map((p) => <DefaultSubcategoryProductCard p={p} />) }
                {filteredProducts && filteredProducts.length === 0 &&
                    <div className={"mb-5 pb-5"}>
                        <h4>Achtung, ihre Filter-Einstellung haben keine Ergebnisse</h4>
                        <GsButton variant={"primary"}
                                  className={"mt-3"}
                            onClick={
                                () => {
                                    setFilterVendor(null);
                                    setFilterProductTypes(productTypes);
                                }
                            }
                        >Alle Filter löschen</GsButton>
                    </div>
                }
            </div>


        </div>
    </Layout>

};

export default DefaultCategoryTemplate;

export const query = graphql`
    query subcategoryQuery($id: String!) {
        shopifyCollection(id: {eq: $id}) {
            id
            title
            handle
            descriptionHtml
            products {
              images {
                originalSrc
              }
              handle
              priceRangeV2 {
                minVariantPrice {
                  amount
                }
                maxVariantPrice {
                  amount
                }
              }
              variants {
                compareAtPrice
              }
              productType
              shopifyId
              title
              vendor
            }
      }
    }`;
